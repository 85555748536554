/* You can add global styles to this file, and also import other style files */
html{
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    height: 100%;
}

body{
    height: 100%;
}

body, div, p, input, label, select, button{
    font-family: 'Montserrat', sans-serif;
}

.btn-primary{
    background-color: #33405D;
    border-color: #33405D;
}

.btn-primary:disabled {
    background-color: #33405D;
    border-color: #33405D;
    opacity: 0.4;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active{
    background-color: 	#232C41;
    border-color: 	#232C41;
}

.form-control.is-invalid, .was-validated .form-control:invalid{
    border-color: #dc3545 !important;
}

.form-control{
    border: 2px solid #33405D;
    min-width: 240px;
}

.main-color{
    color: #33405D;
}

.main-color-hover:hover, .main-color-hover:focus, .main-color-hover:active{
    color: #33405D;
    text-decoration: none;
}

.main-bg-color{
    background-color: #33405D;
}

.margin-top-52{
    margin-top: 52px;
}

.cursor-pointer{
    cursor: pointer;
}

.arrow-flip{
    transform: rotate(180deg);
}

.font-size-note{
    font-size:0.7rem;
}

.overflow-x{
    overflow-x: auto;
}

.loading-bg{
    background-color: rgba(232,232,232, 0.5);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.mat-progress-spinner circle, .mat-spinner circle {
    stroke: #33405D !important;
}

html, body { height: 100%; }
